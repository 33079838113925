@media (max-width: 1024px) {
    .header-menu {
        &__inner {
            .menu-btn {
                font-size: 15px;
                padding: 0 8px;
            }
        }
    }
}


@media (max-width: 820px),
(max-width: 834px) {
    .header-menu__inner {
        .menu-btn {
            font-size: 14px;
            line-height: 15px;
        }
    }
}

@media (max-width: 768px) {
    .header {
        display: block;
        position: static;

        &-menu__logo {
            padding: 0 15px;
        }

        &-menu__inner {
            .menu-btn {
                font-size: 16px;
                line-height: 36px;
            }
        }

        .container {
            padding: 0;
        }
    }

    .nav-menu {
        position: absolute;
        margin: 0;
        padding: 0;
        top: 0;
        display: none;
        // width: 100vh;
        width: 100%;


        &__list {
            padding-top: 25px;
            background: linear-gradient(0deg, rgba(16, 55, 77, 0.87) 0%, #000000 100%);
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            flex-direction: column;

            &--item {
                width: 100%;

                .menu-btn {
                    width: 100%;
                }
            }
        }

        .menu-btn {
            font-size: 14px;
            padding: 0 5px;
        }
    }

    .burger-menu {
        display: block;

        .active {
            transform: rotate(90deg);
        }
    }


    .service-content {
        min-height: 400px;
    }

    .slider {
        &-items {
            display: block;
        }
    }


    .form {
        width: 100%;
        font-size: 15px;

        label {
            display: block;
            width: 100%;
            line-height: 20px;
            margin-bottom: 10px;


            input {
                display: block;
                width: 100%;
                margin-bottom: 10px;
                margin-left: 0px;
            }
        }


    }
}




@media (max-width: 480px),
(max-width: 600px) {
    .header-about-us {
        .text {
            font-size: 18px;
            line-height: 35px;
        }
    }

    .service-content__text {
        width: 100%;
        padding-top: 50px;
        padding-bottom: 25px;
    }

    .service-header__title,
    .data-header__title {
        align-items: center;
        text-align: center;
        font-size: 20px;
        line-height: initial;
    }

    .partners-header__title {
        font-size: 20px;
        align-items: center;
        text-align: center;
    }

    .form {
        form {
            p {
                display: flex;
                flex-direction: column;

                input {
                    width: 100%;
                }
            }
        }
    }

    .header {
        &-contacts {
            font-size: 12px;
            max-width: 100vw;
            align-items: center;
            // flex-direction: row;
        }

        &-top {
            .container {
                flex-direction: column;
                align-items: center;
            }
        }

        &-languages {
            // flex-direction: column;
            align-items: center;
            margin-top: 20px;
            margin-left: 5px;

            .language {
                padding: 0;

                img {
                    margin: 0;
                }
            }
        }
    }

}

@media (max-width: 320px),
(max-width: 396px) {

    .header-about-us {
        .text {
            font-size: 16px;
            line-height: 24px;
        }
    }

    .service-header__title,
    .data-header__title {
        font-size: 18px;
    }

    .service-content__text,
    .data-content {
        font-size: 16px;
        line-height: 25px;
        // padding-bottom: 25px;
    }

    .partners-header__title {
        font-size: 16px;
    }

    .footer__address {
        font-size: 16px;
    }

    .service-btn {
        align-items: center;
        width: 100%;
        font-size: 16px;
    }

    .header {

        &-top {
            .container {
                flex-direction: column;
                align-items: center;
            }


        }

        &-menu__logo {
            padding: 0;
        }

        // &-contacts {
        //     font-size: 8px;
        //     max-width: 100vw;
        //     align-items: center;
        // }

        &-languages {
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            margin-top: 0px;
            margin-left: 0;

            .language {
                padding: 0 10px;

                img {
                    margin: 0;
                }
            }
        }
    }
}

@media (max-height: 740px) {

    .close-form {
        &::before {
            background-color: $green;
        }

        &::after {
            background-color: $green
        }
    }

}