.header {

  background-image: url("../images/bg-fons.jpg");
  // position: fixed;
  top: 0;
  width: 100vw;
  z-index: 1;
  overflow-x: hidden;
  overflow-y: hidden;

  &-top {
    padding: 10px 0 10px 0;

    .container {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  &-logo {
    font-family: "CalibriB";
    // display: flex;
    // width: 100vw;
    // justify-content: center;
    // align-items: center;
    font-size: 35px;
    font-weight: bold;
    text-align: center;
    letter-spacing: 0.1px;
    line-height: .9;
    word-wrap: break-word;
    text-transform: capitalize;
    // margin-right: 20px;
    cursor: pointer;

    a {
      color: #2b9a9f;
    }

    p {
      font-size: 25px;
      text-transform: uppercase;
      color: $green;
      font-weight: 700;
    }

    img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  &-contacts {
    color: #fff;
    display: flex;
    flex-direction: column;
    max-width: 250px;
    font-size: 15px;
    line-height: 1.1;

    p {

      margin: 0;
    }
  }


  &-languages {
    display: flex;
    justify-self: start;
    font-size: 14px;

    .language+.language {
      padding-left: 5px;

      img+img {
        margin-left: 2px;
      }
    }

    .language {
      display: flex;
      align-items: center;
      justify-self: self-end;

      opacity: 0.7;
      transition: all 0.3s;

      &:hover {
        color: $green;
        opacity: 1;
      }

      &:focus {
        color: $green;
        opacity: 1;
      }

      img {
        width: 20px;

      }
    }

    .active {
      color: $green;
      opacity: 1;
      font-weight: bold;
    }
  }



}



.menu-btn {
  display: inline-block;
  font-size: 16px;
  line-height: 36px;
  padding-bottom: 15px;
  padding: 0 16px;
  border: 1px solid transparent;
  transition: all 1s;
  border-width: 1px 5px 5px 1px;

  &:hover {
    border-color: $green;
    right: 0;
    bottom: 0;
    border-width: 1px 5px 5px 1px;
  }

  &:focus {
    border-color: $green;
    right: 0;
    bottom: 0;
    border-width: 1px 5px 5px 1px;
  }
}


// .header-about-us {
//   color: white;
//   background: url('../images/header-bg.jpg') 100%;
//   background-position: bottom;
//   background-size: cover;
//   background-repeat: no-repeat;
//   // height: 90vh;
//   // padding-top: 500px;
//   height: 500px;

//   .text {
//     font-size: 25px;
//     line-height: 45px;
//     padding-top: 200px;
//     // padding-bottom: 250px;
//     text-align: center;
//     text-shadow: 2px 0 2px #000;

//     a {
//       color: $green;
//       cursor: pointer;

//       &:hover {
//         text-decoration: underline;
//       }

//       &:focus {
//         text-decoration: underline;
//       }

//     }
//   }
// }