.data {
    &-content {
        overflow: hidden;
    }

}

.data {

    &-header {
        padding: 25px 0;

        .container {
            display: flex;
            justify-content: center;
        }

        &__title {
            color: $green;
            font-size: 24px;
            font-weight: 500;
            text-transform: uppercase;
            padding: 10px;
            position: relative;
            margin: 0;

            &::after,
            &::before {
                content: '';
                position: absolute;
                width: 50px;
                height: 50px;
                background: url('../images/accessory_for_title.svg');
                background-repeat: no-repeat;
            }

            &::after {
                top: -15px;
                left: -30px;
                transform: rotate(180deg);
            }

            &::before {
                bottom: -30px;
                right: -30px;
            }
        }


        .title {
            line-height: initial;
            display: block;
            position: relative;
        }
    }




    &-content {
        padding-top: 55px;
        background: linear-gradient(180deg, rgba(216, 232, 241, 0) 0%, rgba(204, 221, 231, 0.054375) 0.01%, rgba(16, 55, 77, 0.87) 67.19%);
        position: relative;
        display: flex;
        justify-content: center;
        flex-direction: column;
        font-size: 18px;

        .container {
            p {
                line-height: 1.1;
                margin: 0;
                padding: 0;
                color: #fff;

            }
        }
    }
}