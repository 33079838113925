@font-face {
    font-family: 'icomoon';
    src: url("../fonts/icomoon/icomoon.eot?wfq3mh");
    src: url("../fonts/icomoon/icomoon.eot?wfq3mh#iefix") format("embedded-opentype"), url("../fonts/icomoon/icomoon.ttf?wfq3mh") format("truetype"), url("../fonts/icomoon/icomoon.woff?wfq3mh") format("woff"), url("../fonts/icomoon/icomoon.svg?wfq3mh#icomoon") format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}



[class^='icon-'],
[class*=' icon-'] {
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

.icon-envelope-o:before {
    content: "\f003"
}

.icon-angle-right:before {
    content: "\f105"
}

.icon-bars:before {
    content: "\f0c9"
}

.icon-navicon:before {
    content: "\f0c9"
}

.icon-reorder:before {
    content: "\f0c9"
}

.icon-map-marker:before {
    content: "\f041"
}


@font-face {
    font-family: "Calibri";
    src: url("../fonts/calibri.ttf");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "CalibriB";
    src: url("../fonts/calibri_bold.ttf");
    font-weight: bold;
    font-style: normal;
}