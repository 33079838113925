.contact-form {
    display: none;
    z-index: 2;
    margin: 0 auto;
}

.form {
    position: fixed;
    width: 550px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 40px;
    box-sizing: border-box;
    background-color: #fff;
    color: $green;
    border: 2px dashed $green;
    z-index: 2;


    p {
        display: flex;
        justify-content: space-between;
        margin-bottom: 25px;
        align-items: center;

        label {
            text-transform: capitalize;
        }

        input {
            border-radius: 10px 100px / 120px;
            margin-left: 10px;
            margin-bottom: 8px;
            outline: none;
            height: 40px;
            border: 1px solid $green;
            padding: 0px 10px;
            font-size: 100%;

            &::target {
                border: 2px solid $green;
            }

            &:focus {
                border: 2px solid $green;

            }
        }
    }

    button {
        text-transform: capitalize;
        position: relative;
        font-size: 14px;
        line-height: 24px;
        font-weight: 500;
        padding: 8px 20px 8px 24px;
        background-color: $green;
        border: 1px solid #d4fff4;
        border-right-width: 20px;
        outline: none;
        opacity: 0.8;
        border-radius: 10px 100px / 120px;

        &:hover {
            transition: all .3s;
            opacity: 1;
            background-color: #d4fff4;
            border: 1px solid $green;
            border-right-width: 20px;

            &::after {
                color: #d4fff4;
            }
        }

        &::after {
            content: "\f105";
            font-family: 'icomoon';
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: -12px;
        }
    }
}


.close-form {
    height: 25px;
    width: 25px;
    position: fixed;
    top: 15px;
    right: 15px;
    cursor: pointer;

    z-index: 2;

    &::before {
        content: "";
        background-color: #fff;
        position: absolute;
        height: 1px;
        width: 31px;
        top: 11px;
        left: -4px;
        transform: rotate(-45deg);
    }

    &::after {
        content: "";
        background-color: #fff;
        position: absolute;
        height: 1px;
        width: 31px;
        top: 11px;
        left: -4px;
        transform: rotate(45deg);
    }

    &:hover::before {
        background-color: $green;
        position: absolute;
        height: 1px;
        width: 31px;
        top: 11px;
        left: -4px;
        transform: rotate(-45deg);
    }

    &:hover::after {
        background-color: $green;
        position: absolute;
        height: 1px;
        width: 31px;
        top: 11px;
        left: -4px;
        transform: rotate(45deg);
    }
}