@import 'variables';

@import 'libs/normalize';
@import 'libs/slick-slider';

@import 'fonts';

*,
*::after,
*::before {
    box-sizing: border-box;
}

a {
    text-decoration: none;
    display: inline-block;
    color: #fff;
}

html,
body {
    // overflow-x: hidden;
    width: 100%;
    height: 100%;
    font-family: "Rubik", sans-serif;
    font-size: 16px;
    line-height: 18px;
}

ul,
li {
    list-style: none;
    margin: 0;
    padding: 0;
}

body {
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
}

.app {
    position: relative;
    overflow-x: hidden;
}

.container {
    max-width: 1170px;
    margin: 0 auto;
    padding: 0 15px;
}

.default-btn {
    font-size: 14px;
    line-height: 24px;
    font-weight: 500;
    padding: 8px 20px 8px 24px;
    position: relative;
    border: 1px solid $green;
    border-right-width: 20px;
    outline: none;
    opacity: 0.8;

    &::after {
        content: "\f105";
        font-family: 'icomoon';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: -12px;
    }

    &:hover {
        transition: all .3s;
        opacity: 1;
        background-color: $green;
        border: 1px solid #fff;
        border-right-width: 20px;

        &::after {
            content: "\f105";
            font-family: 'icomoon';
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: -12px;
            color: $green;
        }
    }
}

.bg-shadows {
    position: absolute;
    display: none;
    width: 100vw;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.8);
    position: fixed;
    overflow: auto;
    z-index: 1;
}

.answer {
    display: block;
}

.burger-menu {
    z-index: 1;
    padding: 0 15px;
    cursor: pointer;
    transform: rotate(0deg);
    display: none;
    transition: transform 1s;

    .icon-bars {
        display: block;
        font-size: 35px;
        color: $green;
    }

    .active {
        transform: rotate(90deg);
    }
}


@import 'components/all';
@import 'media';