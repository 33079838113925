.footer {
    background: linear-gradient(180deg, rgba(16, 55, 77, 0.87) 0%, #000000 100%);
    color: #fff;

    &-copy {
        font-size: 13px;
        display: flex;
        justify-content: center;
    }

    &-content {
        display: flex;
        justify-content: center;
        text-align: center;
        padding: 20px 0 30px;
    }

    &__inner {
        display: flex;
        justify-content: space-around;
        color: #fff;
    }

    &__address {
        max-width: 475px;
        line-height: 25px;

        &::before {
            content: '\f041';
            left: 0;
            color: $green;
            font-family: 'icomoon';
            padding-right: 10px;
        }
    }

    &__email {
        display: none;
        min-width: 320px;
        line-height: 25px;
        margin-right: 10px;
    }
}