.service {
  &-btn {
    cursor: pointer;
    position: relative;
    font-size: 14px;
    line-height: 24px;
    font-weight: 500;
    padding: 8px 20px 8px 24px;
    border: 1px solid $green;
    border-right-width: 20px;
    outline: none;
    opacity: 0.8;
    margin-bottom: 55px;

    &:hover {
      transition: all .3s;
      opacity: 1;
      background-color: $green;
      border-color: #fff;

      &::after {
        color: $green;
      }
    }

    &:focus {
      transition: all .3s;
      opacity: 1;
      background-color: $green;
      border-color: #fff;

      &::after {
        color: $green;
      }
    }

    &::after {
      content: "\f105";
      font-family: "icomoon";
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: -12px;
    }
  }

  // .item:nth-child(2n + 1) {
  //   .service-content {
  //     background-color: rgba(16, 55, 77, 0.87);
  //     background: url('../images/bg-fons.jpg');
  //     background-size: cover;
  //     background-repeat: no-repeat;
  //     background-position: center;
  //     transform: scaleX(-1);
  //     display: flex;
  //     justify-content: center;

  //     .container {
  //       transform: scaleX(-1);
  //     }
  //   }
  // }

  .item {
    .service-header {
      .container {
        display: flex;
        justify-content: center;
      }
    }
  }

  &-header {
    padding: 5px 0;
    // .container:nth-child(2n) {
    //   display: flex;
    //   justify-content: flex-end;
    // }

    &__title {
      // display: flex;
      color: $green;
      font-size: 24px;
      font-weight: 500;
      // line-height: 0;
      // flex-direction: column;
      text-transform: uppercase;
      padding: 10px;
      position: relative;
      margin: 0;
      // width: 100%;


      &::after,
      &::before {
        content: '';
        position: absolute;
        width: 50px;
        height: 50px;
        background: url('../images/accessory_for_title.svg');
        background-repeat: no-repeat;
      }

      &::after {
        top: -15px;
        left: -30px;
        transform: rotate(180deg);
      }

      &::before {
        bottom: -30px;
        right: -30px;
      }
    }

    .title {
      font-weight: 700;
      display: block;
      position: relative;
      margin-bottom: 10px;
    }

    .subtitle {
      display: block;
      font-weight: 500;
    }

  }

  &-content {
    background: url('../images/bg-fons.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-color: rgba(16, 55, 77, 0.87);
    position: relative;
    // min-height: 500px;
    color: #fff;
    display: flex;
    justify-content: center;

    &__text {
      max-width: 850px;
      letter-spacing: 0.1px;
      padding-top: 0px;
      padding-bottom: 50px;
      font-size: 20px;
      line-height: 30px;
      word-wrap: break-word;
    }
  }
}